<template>
  <div>
    <b-card class="mb-4">
      <b-card-text>
        <h5 class="card-title">Feather icons</h5>
        <b-row class="icons">
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="activity"></feather>
            </div>
            <div class="ml-2">activity</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="airplay"></feather>
            </div>
            <div class="ml-2">airplay</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="alert-circle"></feather>
            </div>
            <div class="ml-2">alert-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="alert-octagon"></feather>
            </div>
            <div class="ml-2">alert-octagon</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="alert-triangle"></feather>
            </div>
            <div class="ml-2">alert-triangle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="align-center"></feather>
            </div>
            <div class="ml-2">align-center</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="align-justify"></feather>
            </div>
            <div class="ml-2">align-justify</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="align-left"></feather>
            </div>
            <div class="ml-2">align-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="align-right"></feather>
            </div>
            <div class="ml-2">align-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="anchor"></feather>
            </div>
            <div class="ml-2">anchor</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="aperture"></feather>
            </div>
            <div class="ml-2">aperture</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="archive"></feather>
            </div>
            <div class="ml-2">archive</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-down"></feather>
            </div>
            <div class="ml-2">arrow-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-down-circle"></feather>
            </div>
            <div class="ml-2">arrow-down-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-down-left"></feather>
            </div>
            <div class="ml-2">arrow-down-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-down-right"></feather>
            </div>
            <div class="ml-2">arrow-down-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-left"></feather>
            </div>
            <div class="ml-2">arrow-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-left-circle"></feather>
            </div>
            <div class="ml-2">arrow-left-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-right"></feather>
            </div>
            <div class="ml-2">arrow-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-right-circle"></feather>
            </div>
            <div class="ml-2">arrow-right-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-up"></feather>
            </div>
            <div class="ml-2">arrow-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-up-circle"></feather>
            </div>
            <div class="ml-2">arrow-up-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-up-left"></feather>
            </div>
            <div class="ml-2">arrow-up-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="arrow-up-right"></feather>
            </div>
            <div class="ml-2">arrow-up-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="at-sign"></feather>
            </div>
            <div class="ml-2">at-sign</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="award"></feather>
            </div>
            <div class="ml-2">award</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bar-chart"></feather>
            </div>
            <div class="ml-2">bar-chart</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bar-chart-2"></feather>
            </div>
            <div class="ml-2">bar-chart-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="battery"></feather>
            </div>
            <div class="ml-2">battery</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="battery-charging"></feather>
            </div>
            <div class="ml-2">battery-charging</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bell"></feather>
            </div>
            <div class="ml-2">bell</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bell-off"></feather>
            </div>
            <div class="ml-2">bell-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bluetooth"></feather>
            </div>
            <div class="ml-2">bluetooth</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bold"></feather>
            </div>
            <div class="ml-2">bold</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="book"></feather>
            </div>
            <div class="ml-2">book</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="book-open"></feather>
            </div>
            <div class="ml-2">book-open</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="bookmark"></feather>
            </div>
            <div class="ml-2">bookmark</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="box"></feather>
            </div>
            <div class="ml-2">box</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="briefcase"></feather>
            </div>
            <div class="ml-2">briefcase</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="calendar"></feather>
            </div>
            <div class="ml-2">calendar</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="camera"></feather>
            </div>
            <div class="ml-2">camera</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="camera-off"></feather>
            </div>
            <div class="ml-2">camera-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cast"></feather>
            </div>
            <div class="ml-2">cast</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="check"></feather>
            </div>
            <div class="ml-2">check</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="check-circle"></feather>
            </div>
            <div class="ml-2">check-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="check-square"></feather>
            </div>
            <div class="ml-2">check-square</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevron-down"></feather>
            </div>
            <div class="ml-2">chevron-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevron-left"></feather>
            </div>
            <div class="ml-2">chevron-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevron-right"></feather>
            </div>
            <div class="ml-2">chevron-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevron-up"></feather>
            </div>
            <div class="ml-2">chevron-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevrons-down"></feather>
            </div>
            <div class="ml-2">chevrons-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevrons-left"></feather>
            </div>
            <div class="ml-2">chevrons-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevrons-right"></feather>
            </div>
            <div class="ml-2">chevrons-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chevrons-up"></feather>
            </div>
            <div class="ml-2">chevrons-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="chrome"></feather>
            </div>
            <div class="ml-2">chrome</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="circle"></feather>
            </div>
            <div class="ml-2">circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="clipboard"></feather>
            </div>
            <div class="ml-2">clipboard</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="clock"></feather>
            </div>
            <div class="ml-2">clock</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cloud"></feather>
            </div>
            <div class="ml-2">cloud</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cloud-drizzle"></feather>
            </div>
            <div class="ml-2">cloud-drizzle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cloud-lightning"></feather>
            </div>
            <div class="ml-2">cloud-lightning</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cloud-off"></feather>
            </div>
            <div class="ml-2">cloud-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cloud-rain"></feather>
            </div>
            <div class="ml-2">cloud-rain</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cloud-snow"></feather>
            </div>
            <div class="ml-2">cloud-snow</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="code"></feather>
            </div>
            <div class="ml-2">code</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="codepen"></feather>
            </div>
            <div class="ml-2">codepen</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="command"></feather>
            </div>
            <div class="ml-2">command</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="compass"></feather>
            </div>
            <div class="ml-2">compass</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="copy"></feather>
            </div>
            <div class="ml-2">copy</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-down-left"></feather>
            </div>
            <div class="ml-2">corner-down-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-down-right"></feather>
            </div>
            <div class="ml-2">corner-down-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-left-down"></feather>
            </div>
            <div class="ml-2">corner-left-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-left-up"></feather>
            </div>
            <div class="ml-2">corner-left-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-right-down"></feather>
            </div>
            <div class="ml-2">corner-right-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-right-up"></feather>
            </div>
            <div class="ml-2">corner-right-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-up-left"></feather>
            </div>
            <div class="ml-2">corner-up-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="corner-up-right"></feather>
            </div>
            <div class="ml-2">corner-up-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="cpu"></feather>
            </div>
            <div class="ml-2">cpu</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="credit-card"></feather>
            </div>
            <div class="ml-2">credit-card</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="crop"></feather>
            </div>
            <div class="ml-2">crop</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="crosshair"></feather>
            </div>
            <div class="ml-2">crosshair</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="database"></feather>
            </div>
            <div class="ml-2">database</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="delete"></feather>
            </div>
            <div class="ml-2">delete</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="disc"></feather>
            </div>
            <div class="ml-2">disc</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="dollar-sign"></feather>
            </div>
            <div class="ml-2">dollar-sign</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="download"></feather>
            </div>
            <div class="ml-2">download</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="download-cloud"></feather>
            </div>
            <div class="ml-2">download-cloud</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="droplet"></feather>
            </div>
            <div class="ml-2">droplet</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="edit"></feather>
            </div>
            <div class="ml-2">edit</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="edit-2"></feather>
            </div>
            <div class="ml-2">edit-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="edit-3"></feather>
            </div>
            <div class="ml-2">edit-3</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="external-link"></feather>
            </div>
            <div class="ml-2">external-link</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="eye"></feather>
            </div>
            <div class="ml-2">eye</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="eye-off"></feather>
            </div>
            <div class="ml-2">eye-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="facebook"></feather>
            </div>
            <div class="ml-2">facebook</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="fast-forward"></feather>
            </div>
            <div class="ml-2">fast-forward</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="feather"></feather>
            </div>
            <div class="ml-2">feather</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="file"></feather>
            </div>
            <div class="ml-2">file</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="file-minus"></feather>
            </div>
            <div class="ml-2">file-minus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="file-plus"></feather>
            </div>
            <div class="ml-2">file-plus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="file-text"></feather>
            </div>
            <div class="ml-2">file-text</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="film"></feather>
            </div>
            <div class="ml-2">film</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="filter"></feather>
            </div>
            <div class="ml-2">filter</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="flag"></feather>
            </div>
            <div class="ml-2">flag</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="folder"></feather>
            </div>
            <div class="ml-2">folder</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="folder-minus"></feather>
            </div>
            <div class="ml-2">folder-minus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="folder-plus"></feather>
            </div>
            <div class="ml-2">folder-plus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="gift"></feather>
            </div>
            <div class="ml-2">gift</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="git-branch"></feather>
            </div>
            <div class="ml-2">git-branch</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="git-commit"></feather>
            </div>
            <div class="ml-2">git-commit</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="git-merge"></feather>
            </div>
            <div class="ml-2">git-merge</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="git-pull-request"></feather>
            </div>
            <div class="ml-2">git-pull-request</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="github"></feather>
            </div>
            <div class="ml-2">github</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="gitlab"></feather>
            </div>
            <div class="ml-2">gitlab</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="globe"></feather>
            </div>
            <div class="ml-2">globe</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="grid"></feather>
            </div>
            <div class="ml-2">grid</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="hard-drive"></feather>
            </div>
            <div class="ml-2">hard-drive</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="hash"></feather>
            </div>
            <div class="ml-2">hash</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="headphones"></feather>
            </div>
            <div class="ml-2">headphones</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="heart"></feather>
            </div>
            <div class="ml-2">heart</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="help-circle"></feather>
            </div>
            <div class="ml-2">help-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="home"></feather>
            </div>
            <div class="ml-2">home</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="image"></feather>
            </div>
            <div class="ml-2">image</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="inbox"></feather>
            </div>
            <div class="ml-2">inbox</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="info"></feather>
            </div>
            <div class="ml-2">info</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="instagram"></feather>
            </div>
            <div class="ml-2">instagram</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="italic"></feather>
            </div>
            <div class="ml-2">italic</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="layers"></feather>
            </div>
            <div class="ml-2">layers</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="layout"></feather>
            </div>
            <div class="ml-2">layout</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="life-buoy"></feather>
            </div>
            <div class="ml-2">life-buoy</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="link"></feather>
            </div>
            <div class="ml-2">link</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="link-2"></feather>
            </div>
            <div class="ml-2">link-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="linkedin"></feather>
            </div>
            <div class="ml-2">linkedin</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="list"></feather>
            </div>
            <div class="ml-2">list</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="loader"></feather>
            </div>
            <div class="ml-2">loader</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="lock"></feather>
            </div>
            <div class="ml-2">lock</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="log-in"></feather>
            </div>
            <div class="ml-2">log-in</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="log-out"></feather>
            </div>
            <div class="ml-2">log-out</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="mail"></feather>
            </div>
            <div class="ml-2">mail</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="map"></feather>
            </div>
            <div class="ml-2">map</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="map-pin"></feather>
            </div>
            <div class="ml-2">map-pin</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="maximize"></feather>
            </div>
            <div class="ml-2">maximize</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="maximize-2"></feather>
            </div>
            <div class="ml-2">maximize-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="menu"></feather>
            </div>
            <div class="ml-2">menu</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="message-circle"></feather>
            </div>
            <div class="ml-2">message-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="message-square"></feather>
            </div>
            <div class="ml-2">message-square</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="mic"></feather>
            </div>
            <div class="ml-2">mic</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="mic-off"></feather>
            </div>
            <div class="ml-2">mic-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="minimize"></feather>
            </div>
            <div class="ml-2">minimize</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="minimize-2"></feather>
            </div>
            <div class="ml-2">minimize-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="minus"></feather>
            </div>
            <div class="ml-2">minus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="minus-circle"></feather>
            </div>
            <div class="ml-2">minus-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="minus-square"></feather>
            </div>
            <div class="ml-2">minus-square</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="monitor"></feather>
            </div>
            <div class="ml-2">monitor</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="moon"></feather>
            </div>
            <div class="ml-2">moon</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="more-horizontal"></feather>
            </div>
            <div class="ml-2">more-horizontal</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="more-vertical"></feather>
            </div>
            <div class="ml-2">more-vertical</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="move"></feather>
            </div>
            <div class="ml-2">move</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="music"></feather>
            </div>
            <div class="ml-2">music</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="navigation"></feather>
            </div>
            <div class="ml-2">navigation</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="navigation-2"></feather>
            </div>
            <div class="ml-2">navigation-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="octagon"></feather>
            </div>
            <div class="ml-2">octagon</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="package"></feather>
            </div>
            <div class="ml-2">package</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="paperclip"></feather>
            </div>
            <div class="ml-2">paperclip</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="pause"></feather>
            </div>
            <div class="ml-2">pause</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="pause-circle"></feather>
            </div>
            <div class="ml-2">pause-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="percent"></feather>
            </div>
            <div class="ml-2">percent</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone"></feather>
            </div>
            <div class="ml-2">phone</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone-call"></feather>
            </div>
            <div class="ml-2">phone-call</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone-forwarded"></feather>
            </div>
            <div class="ml-2">phone-forwarded</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone-incoming"></feather>
            </div>
            <div class="ml-2">phone-incoming</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone-missed"></feather>
            </div>
            <div class="ml-2">phone-missed</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone-off"></feather>
            </div>
            <div class="ml-2">phone-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="phone-outgoing"></feather>
            </div>
            <div class="ml-2">phone-outgoing</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="pie-chart"></feather>
            </div>
            <div class="ml-2">pie-chart</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="play"></feather>
            </div>
            <div class="ml-2">play</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="play-circle"></feather>
            </div>
            <div class="ml-2">play-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="plus"></feather>
            </div>
            <div class="ml-2">plus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="plus-circle"></feather>
            </div>
            <div class="ml-2">plus-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="plus-square"></feather>
            </div>
            <div class="ml-2">plus-square</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="pocket"></feather>
            </div>
            <div class="ml-2">pocket</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="power"></feather>
            </div>
            <div class="ml-2">power</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="printer"></feather>
            </div>
            <div class="ml-2">printer</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="radio"></feather>
            </div>
            <div class="ml-2">radio</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="refresh-ccw"></feather>
            </div>
            <div class="ml-2">refresh-ccw</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="refresh-cw"></feather>
            </div>
            <div class="ml-2">refresh-cw</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="repeat"></feather>
            </div>
            <div class="ml-2">repeat</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="rewind"></feather>
            </div>
            <div class="ml-2">rewind</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="rotate-ccw"></feather>
            </div>
            <div class="ml-2">rotate-ccw</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="rotate-cw"></feather>
            </div>
            <div class="ml-2">rotate-cw</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="rss"></feather>
            </div>
            <div class="ml-2">rss</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="save"></feather>
            </div>
            <div class="ml-2">save</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="scissors"></feather>
            </div>
            <div class="ml-2">scissors</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="search"></feather>
            </div>
            <div class="ml-2">search</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="send"></feather>
            </div>
            <div class="ml-2">send</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="server"></feather>
            </div>
            <div class="ml-2">server</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="settings"></feather>
            </div>
            <div class="ml-2">settings</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="share"></feather>
            </div>
            <div class="ml-2">share</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="share-2"></feather>
            </div>
            <div class="ml-2">share-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="shield"></feather>
            </div>
            <div class="ml-2">shield</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="shield-off"></feather>
            </div>
            <div class="ml-2">shield-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="shopping-bag"></feather>
            </div>
            <div class="ml-2">shopping-bag</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="shopping-cart"></feather>
            </div>
            <div class="ml-2">shopping-cart</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="shuffle"></feather>
            </div>
            <div class="ml-2">shuffle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="sidebar"></feather>
            </div>
            <div class="ml-2">sidebar</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="skip-back"></feather>
            </div>
            <div class="ml-2">skip-back</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="skip-forward"></feather>
            </div>
            <div class="ml-2">skip-forward</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="slack"></feather>
            </div>
            <div class="ml-2">slack</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="slash"></feather>
            </div>
            <div class="ml-2">slash</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="sliders"></feather>
            </div>
            <div class="ml-2">sliders</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="smartphone"></feather>
            </div>
            <div class="ml-2">smartphone</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="speaker"></feather>
            </div>
            <div class="ml-2">speaker</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="square"></feather>
            </div>
            <div class="ml-2">square</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="star"></feather>
            </div>
            <div class="ml-2">star</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="stop-circle"></feather>
            </div>
            <div class="ml-2">stop-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="sun"></feather>
            </div>
            <div class="ml-2">sun</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="sunrise"></feather>
            </div>
            <div class="ml-2">sunrise</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="sunset"></feather>
            </div>
            <div class="ml-2">sunset</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="tablet"></feather>
            </div>
            <div class="ml-2">tablet</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="tag"></feather>
            </div>
            <div class="ml-2">tag</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="target"></feather>
            </div>
            <div class="ml-2">target</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="terminal"></feather>
            </div>
            <div class="ml-2">terminal</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="thermometer"></feather>
            </div>
            <div class="ml-2">thermometer</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="thumbs-down"></feather>
            </div>
            <div class="ml-2">thumbs-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="thumbs-up"></feather>
            </div>
            <div class="ml-2">thumbs-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="toggle-left"></feather>
            </div>
            <div class="ml-2">toggle-left</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="toggle-right"></feather>
            </div>
            <div class="ml-2">toggle-right</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="trash"></feather>
            </div>
            <div class="ml-2">trash</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="trash-2"></feather>
            </div>
            <div class="ml-2">trash-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="trending-down"></feather>
            </div>
            <div class="ml-2">trending-down</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="trending-up"></feather>
            </div>
            <div class="ml-2">trending-up</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="triangle"></feather>
            </div>
            <div class="ml-2">triangle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="truck"></feather>
            </div>
            <div class="ml-2">truck</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="tv"></feather>
            </div>
            <div class="ml-2">tv</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="twitter"></feather>
            </div>
            <div class="ml-2">twitter</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="type"></feather>
            </div>
            <div class="ml-2">type</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="umbrella"></feather>
            </div>
            <div class="ml-2">umbrella</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="underline"></feather>
            </div>
            <div class="ml-2">underline</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="unlock"></feather>
            </div>
            <div class="ml-2">unlock</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="upload"></feather>
            </div>
            <div class="ml-2">upload</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="upload-cloud"></feather>
            </div>
            <div class="ml-2">upload-cloud</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="user"></feather>
            </div>
            <div class="ml-2">user</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="user-check"></feather>
            </div>
            <div class="ml-2">user-check</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="user-minus"></feather>
            </div>
            <div class="ml-2">user-minus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="user-plus"></feather>
            </div>
            <div class="ml-2">user-plus</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="user-x"></feather>
            </div>
            <div class="ml-2">user-x</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="users"></feather>
            </div>
            <div class="ml-2">users</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="video"></feather>
            </div>
            <div class="ml-2">video</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="video-off"></feather>
            </div>
            <div class="ml-2">video-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="voicemail"></feather>
            </div>
            <div class="ml-2">voicemail</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="volume"></feather>
            </div>
            <div class="ml-2">volume</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="volume-1"></feather>
            </div>
            <div class="ml-2">volume-1</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="volume-2"></feather>
            </div>
            <div class="ml-2">volume-2</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="volume-x"></feather>
            </div>
            <div class="ml-2">volume-x</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="watch"></feather>
            </div>
            <div class="ml-2">watch</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="wifi"></feather>
            </div>
            <div class="ml-2">wifi</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="wifi-off"></feather>
            </div>
            <div class="ml-2">wifi-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="wind"></feather>
            </div>
            <div class="ml-2">wind</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="x"></feather>
            </div>
            <div class="ml-2">x</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="x-circle"></feather>
            </div>
            <div class="ml-2">x-circle</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="x-square"></feather>
            </div>
            <div class="ml-2">x-square</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="youtube"></feather>
            </div>
            <div class="ml-2">youtube</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="zap"></feather>
            </div>
            <div class="ml-2">zap</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="zap-off"></feather>
            </div>
            <div class="ml-2">zap-off</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="zoom-in"></feather>
            </div>
            <div class="ml-2">zoom-in</div>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="6"
            class="my-2 text-truncate d-flex align-items-center"
          >
            <div class="icon mb-n1">
              <feather class="feather-sm" type="zoom-out"></feather>
            </div>
            <div class="ml-2">zoom-out</div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FeatherIcons",
  data: () => ({
    page: {
      title: "Feather Icons",
    },
  }),
};
</script>